import React, { ClipboardEvent } from 'react';
import { InputNumberProps as AntdInputNumberProps } from 'antd';
import currency from 'currency.js';
import * as S from './InputNumber.styles';

// Define currency formats
const EURO_CURRENCY_FORMAT = new RegExp(/^\d{1,3}(\.\d{3})*(,\d+)?$/);
const SIMPLE_EURO_CURRENCY_FORMAT = new RegExp(/^\d+,\d{2}$/);

export interface InputNumberProps extends AntdInputNumberProps {
  className?: string;
  $block?: boolean;
}

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  ({ className, children, $block, onChange, onPaste, ...props }, ref) => {
    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
      // Prevent default paste behavior to manually control the input value
      e.preventDefault();

      const pasteContent = e.clipboardData.getData('text').replace(/[^\d.,]/g, ''); // Keep only digits, dots, and commas

      if (pasteContent) {
        try {
          const isEuroFormat =
            EURO_CURRENCY_FORMAT.test(pasteContent) || SIMPLE_EURO_CURRENCY_FORMAT.test(pasteContent);

          // Attempt to parse using currency.js
          const parsedInput = isEuroFormat
            ? currency(pasteContent.replace(/\./g, '').replace(',', '.'), {
                // Convert Euro format (e.g., 1.234,56) to standard numeric string (1234.56)
                separator: '', // No thousands separator during parsing
                decimal: '.', // Standard decimal point
                fromCents: false, // Input is not in cents
              }).value
            : currency(pasteContent.replace(/,/g, ''), {
                // Assume standard format (e.g., 1,234.56 or 1234.56), remove commas
                separator: '', // No thousands separator during parsing
                decimal: '.', // Standard decimal point
                fromCents: false, // Input is not in cents
              }).value;

          // Check if parsing was successful and resulted in a valid number
          if (typeof parsedInput === 'number' && !isNaN(parsedInput)) {
            // Call the original onChange handler with the parsed numeric value
            onChange?.(parsedInput);
          } else {
            // Optional: Handle cases where parsing fails (e.g., show an error, clear input, or do nothing)
            console.warn('Pasted content could not be parsed into a valid number:', pasteContent);
          }
        } catch (error) {
          console.error('Error parsing pasted content:', error);
        }
      }

      // Call the original onPaste handler if it exists
      onPaste?.(e);
    };

    return (
      <S.InputNumber
        ref={ref}
        className={className}
        $block={$block}
        onChange={onChange} // Pass original onChange
        onPaste={handlePaste} // Use the custom paste handler
        {...props}
      >
        {children}
      </S.InputNumber>
    );
  },
);
