import { BORDER_RADIUS } from '@app/styles/themes/constants';
import { Button } from 'components/common/buttons/Button/Button';
import styled from 'styled-components';

export const Btn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  &.ant-btn-icon-only.ant-btn-sm {
    height: 1.875rem;
  }
`;

export const ButtonGroup = styled.div`
  display: inline-flex;
  padding: 0.325rem;
  column-gap: 0.325rem;

  border-radius: ${BORDER_RADIUS};

  background-color: rgba(var(--primary-rgb-color), 0.1);

  &:not(:last-of-type) {
    margin-bottom: 0.625rem;
  }
`;
