import { notificationController } from '@app/controllers/notificationController';
import { UserModel } from '@app/domain/UserModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doUpdateUser } from '@app/store/slices/userSlice';
import { Button, Card, Col, Input, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { DEFAULT_LOTTIE_CONFIG } from '@app/utils/utils';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { EnumPaymentInformationCurrency, EnumPaymentInformationMethod, PaymentInformation } from '@app/api/user.api';

interface PaymentInformationsFormProps {
  user: UserModel | null;
}

const PaymentInformationsForm = ({ user }: PaymentInformationsFormProps) => {
  const [paymentInformations, setPaymentInformations] = useState<PaymentInformation[]>(user?.paymentInformations || []);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [isAnimationRunning, setIsAnimationRunning] = useState<boolean>(false);
  const [editingInfo, setEditingInfo] = useState<PaymentInformation | null>(null);
  const [paymentInformationForm] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);

  useEffect(() => {
    if (user?.paymentInformations) {
      setPaymentInformations(user.paymentInformations);
    }
  }, [user]);

  const updateUserPaymentInformation = (paymentInfo: PaymentInformation) => {
    setIsLoading(true);
    if (!user) {
      setIsLoading(false);
      notificationController.error({ message: t('login.loginFailed') });
      return;
    }

    dispatch(
      doUpdateUser({
        userId: user.id,
        paymentInformation: paymentInfo,
      }),
    ).then((response) => {
      if (response) {
        setIsLoading(true);
        setIsAnimationRunning(true);
        setPaymentInformations((prevState) => {
          const index = prevState.findIndex((info) => info.id === paymentInfo.id);
          if (index !== -1) {
            // Update existing payment information
            return prevState.map((info) => (info.id === paymentInfo.id ? paymentInfo : info));
          } else {
            // Add new payment information
            return [...prevState, paymentInfo];
          }
        });

        setTimeout(() => {
          setIsLoading(false);
          setEditingInfo(null);
          setIsLoading(false);
          setIsAnimationRunning(false);
        }, 600);
      }
    });
  };

  const handleEdit = (info: PaymentInformation) => {
    setEditingInfo(info);
    paymentInformationForm.setFieldsValue(info);
  };

  const handleAdd = () => {
    const newPaymentInfo: PaymentInformation = {
      account: '',
      method: EnumPaymentInformationMethod.DebitAccount,
      currency: EnumPaymentInformationCurrency.EUR,
      bankIdentifier: '',
      id: '',
    };
    setPaymentInformations([...paymentInformations, newPaymentInfo]);
    setEditingInfo(newPaymentInfo);
    paymentInformationForm.resetFields();
  };

  const handleSave = (values: PaymentInformation) => {
    const paymentInfo = editingInfo?.id ? { ...values, id: editingInfo.id } : values;
    updateUserPaymentInformation(paymentInfo);
  };

  if (isAnimationRunning) {
    return <Lottie options={DEFAULT_LOTTIE_CONFIG} height={150} width={150} />;
  }

  return (
    <Col xs={24} style={{ marginTop: 40 }}>
      <BaseButtonsForm.Title>{t('forms.paymentInfo.title')}</BaseButtonsForm.Title>
      <Row gutter={[16, 16]}>
        {paymentInformations.map((info: PaymentInformation) => {
          return (
            <Col xs={24} sm={12} key={info.account}>
              {editingInfo?.id === info.id ? (
                <BaseButtonsForm
                  form={paymentInformationForm}
                  name={`paymentInformationForm-${info.id}`}
                  onFinish={handleSave}
                  initialValues={info}
                  isFieldsChanged={isFieldsChanged}
                  onFieldsChange={() => setFieldsChanged(true)}
                  cancelLogic={() => setEditingInfo(null)}
                >
                  <Card>
                    <BaseButtonsForm.Item
                      name="account"
                      label={t('forms.paymentInfo.account')}
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <Input
                        onChange={(e) => {
                          const { value } = e.target;
                          paymentInformationForm.setFieldsValue({ account: value.toUpperCase() });
                        }}
                      />
                    </BaseButtonsForm.Item>
                    <BaseButtonsForm.Item
                      name="bankIdentifier"
                      label={t('forms.paymentInfo.bankIdentifier')}
                      required={
                        paymentInformationForm.getFieldValue('currency') &&
                        paymentInformationForm.getFieldValue('currency') !== EnumPaymentInformationCurrency.EUR
                          ? true
                          : false
                      }
                    >
                      <Input
                        onChange={(e) => {
                          const { value } = e.target;
                          paymentInformationForm.setFieldsValue({ bankIdentifier: value.toUpperCase() });
                        }}
                      />
                    </BaseButtonsForm.Item>
                    <BaseButtonsForm.Item
                      name="method"
                      label={t('forms.paymentInfo.method')}
                      rules={[{ required: false, message: t('common.requiredField') }]}
                    >
                      <Select disabled>
                        <Select.Option value={EnumPaymentInformationMethod.DebitAccount}>
                          <span
                            style={{
                              color: 'var(--text-main-color)',
                            }}
                          >
                            {t('forms.paymentInfo.methods.DebitAccount')}
                          </span>
                        </Select.Option>
                        {/* <Select.Option value={EnumPaymentInformationMethod.PayPal}>
                              {EnumPaymentInformationMethod.PayPal}
                            </Select.Option> */}
                      </Select>
                    </BaseButtonsForm.Item>
                    <BaseButtonsForm.Item name="currency" label={t('forms.paymentInfo.currency')}>
                      <Select defaultActiveFirstOption>
                        <Select.Option value="">{t('forms.paymentInfo.currencies.all')}</Select.Option>
                        <Select.Option value="EUR">{t('forms.paymentInfo.currencies.eur')}</Select.Option>
                        <Select.Option value="USD">{t('forms.paymentInfo.currencies.usd')}</Select.Option>
                        <Select.Option value="CHF">{t('forms.paymentInfo.currencies.chf')}</Select.Option>
                        <Select.Option value="GBP">{t('forms.paymentInfo.currencies.gbp')}</Select.Option>
                        <Select.Option value="PLN">{t('forms.paymentInfo.currencies.pln')}</Select.Option>
                        <Select.Option value="DKK">{t('forms.paymentInfo.currencies.dkk')}</Select.Option>
                        <Select.Option value="NOK">{t('forms.paymentInfo.currencies.nok')}</Select.Option>
                        <Select.Option value="SEK">{t('forms.paymentInfo.currencies.sek')}</Select.Option>
                      </Select>
                    </BaseButtonsForm.Item>
                  </Card>
                </BaseButtonsForm>
              ) : (
                <Card
                  loading={isLoading}
                  actions={[
                    <EditOutlined
                      style={{ color: 'var(--text-main-color)' }}
                      key="edit"
                      onClick={() => handleEdit(info)}
                    />,
                  ]}
                >
                  <Card.Meta
                    title={`${t('forms.paymentInfo.methods.' + info.method)}`}
                    description={
                      <>
                        <Typography.Text style={{ marginBottom: 10, display: 'block' }}>{`${t(
                          'forms.paymentInfo.account',
                        )}: ${info.account}`}</Typography.Text>

                        {info.bankIdentifier && info.account && (
                          <Typography.Text style={{ marginBottom: 10, display: 'block' }}>{`${t(
                            'forms.paymentInfo.bankIdentifier',
                          )}: ${info.bankIdentifier}`}</Typography.Text>
                        )}

                        {info.currency && (
                          <Typography.Text style={{ marginBottom: 10, display: 'block' }}>{`${t(
                            'forms.paymentInfo.currency',
                          )}: ${info.currency}`}</Typography.Text>
                        )}
                      </>
                    }
                  />
                </Card>
              )}
            </Col>
          );
        })}
        <Col xs={24} sm={12}>
          <Button
            type="dashed"
            onClick={handleAdd}
            icon={<PlusOutlined />}
            style={{ width: '100%', color: 'var(--ant-success-color)' }}
          >
            {t('common.add')}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default PaymentInformationsForm;
