import { httpApi } from '@app/api/http.api';
import { ClaimModel } from '@app/domain/ClaimModel';
import { Key } from 'react';

export interface AuthData {
  username: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  country: string;
}

export interface SecurityCodePayload {
  email: string;
  code: string;
}

export interface NewPasswordData {
  username: string;
  oldPassword: string;
  newPassword: string;
}

export interface NewResetPasswordPayload {
  userId: string;
  verifier: string;
  newPassword: string;
}

export interface CurrentPasswordData {
  username: string;
  currentPassword: string;
}

export interface GetUserClaimsFilter {
  creditorFeedbackRequired?: boolean;
  status?: string;
  stage?: string;
  currency?: string;
  payments?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    some?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    none?: any;
  };
  activities?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    some?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    none?: any;
  };
  paymentPlans?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    some?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    none?: any;
  };
  searchString?: string;
}

export interface GetUserClaimsByCreditorRequest {
  userId: string;
  filters?: GetUserClaimsFilter[];
  orderBy?: { property: Key; order: 'asc' | 'desc' };
  skip?: number;
  take?: number;
}

export interface CreateVerificationCodeResponse {
  id: string;
  verifier: string;
}

export type GetUserClaimsByCreditorResponse = Array<ClaimModel>;

export interface GetUserRequest {
  userId: string;
}

export enum EnumPaymentInformationMethod {
  DebitAccount = 'DebitAccount',
  PayPal = 'PayPal',
}

export enum EnumPaymentInformationCurrency {
  EUR = 'EUR',
  USD = 'USD',
  CHF = 'CHF',
  GBP = 'GBP',
  PLN = 'PLN',
  DKK = 'DKK',
  NOK = 'NOK',
  SEK = 'SEK',
}

export interface PaymentInformation {
  id?: string;
  account?: string;
  bankIdentifier?: string;
  method?: EnumPaymentInformationMethod;
  currency?: EnumPaymentInformationCurrency;
}

export interface GetUserResponse {
  address?: {
    id?: string;
    addressLine1?: string;
    addressLine2?: string;
    country?: string;
    postalcode?: string;
    town?: string;
  };
  businessName?: string;
  utmSource?: string;
  businessType?: string;
  comment?: string;
  contactName?: string;
  createdAt: Date;
  email?: string;
  id: string;
  industry?: string;
  name?: string;
  paymentInformation?: PaymentInformation;
  vatId?: string;
  isVerified?: boolean;
  country?: string;
  phone?: string;
  roles: string[];
  updatedAt: Date;
  username: string;
  analyticalRecipients?: string[];
  financialRecipients?: string[];
  operationalRecipients?: string[];
}

// for now, extend the GetUserResponse, as its the same
export interface UpdateUserRequest extends Partial<GetUserResponse> {
  userId: string;
}

export interface CreateUserRequest extends Partial<GetUserResponse> {
  password: string;
}

export interface CreateUserResponse {
  id: string;
}

export interface UpdateUserResponse extends GetUserResponse {}

export interface UpdateUserPasswordRequest {
  userId: string;
  success: boolean;
}

export interface verifyCurrentPasswordRequest {
  userId: string;
  verified: boolean;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  id: string;
  roles: string[];
  username: string;
}

export interface UpdatePasswordRequest {
  newPassword: string;
  username: string;
  accessToken: string;
}

export interface UserUpdatePasswordResponse {
  accessToken: string;
  addressId: string | null;
  allowPartnerLawyerInteraction: boolean;
  analyticalRecipients: string | null;
  birthday: string | null;
  businessName: string;
  businessType: string;
  comment: string;
  contactName: string;
  createdAt: string;
  customClauses: string | null;
  email: string;
  emailVerificationStatus: string;
  financialRecipients: string | null;
  hasActiveCreditRatingSubscription: boolean;
  hasActiveReminderSubscription: boolean;
  hasActiveReminderSubscriptionWithCalling: boolean;
  hasLanguageKnowledge: string | null;
  hasLegalKnowledge: string | null;
  id: string;
  imageUrl: string | null;
  industry: string | null;
  integrationId: string | null;
  isVerified: boolean;
  managerId: string | null;
  maximumAmountB2B: number | null;
  maximumAmountB2C: number | null;
  maximumPaymentPlanMonths: number;
  minimumAmountB2B: number | null;
  minimumAmountB2C: number | null;
  name: string;
  operationalRecipients: string | null;
  password: string;
  paymentInformationId: string | null;
  phone: string | null;
  phoneVerificationStatus: string;
  roles: string[];
  successFeeInPercent: number;
  taxDeductionAllowed: boolean;
  updatedAt: string;
  userType: string;
  username: string;
  vatId: string | null;
}

export interface UserResetPasswordPayload {
  email: string;
}

export interface UserResetPasswordResponse {
  id: string;
  email: string;
  mailId: string;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('login', { ...loginPayload }).then(({ data }) => data);

export const getUser = (getUserPayload: GetUserRequest): Promise<GetUserResponse> =>
  httpApi.get<GetUserResponse>('users/' + getUserPayload.userId).then(({ data }) => data);

export const createUser = (createUserPayload: CreateUserRequest): Promise<CreateUserResponse> =>
  httpApi.post<UpdateUserResponse>('users', createUserPayload).then(({ data }) => data);

export const updateUser = (updateUserPayload: UpdateUserRequest): Promise<UpdateUserResponse> =>
  httpApi
    .patch<UpdateUserResponse>(
      'users/' + updateUserPayload.userId,
      Object.fromEntries(Object.entries(updateUserPayload).filter((e) => e[0] != 'userId')),
    )
    .then(({ data }) => data);

export const getUserClaimsAsCreditor = (
  getUserClaimsByCreditorPayload: GetUserClaimsByCreditorRequest,
): Promise<GetUserClaimsByCreditorResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parameters: any = {};
  getUserClaimsByCreditorPayload.filters?.map((item) => {
    if (!!item.status) {
      parameters['where[status]'] = item.status;
    }
    if (!!item.stage) {
      parameters['where[stage]'] = item.stage;
    }
    if (!!item.currency) {
      parameters['where[currency]'] = item.currency;
    }
    if (item.searchString) {
      parameters['searchString'] = item.searchString;
    }
    if (item.payments && item.payments.some?.paymentType !== undefined) {
      parameters['where[payments][some][paymentType]'] = item.payments.some?.paymentType;
    }
    if (item.payments && item.payments.some?.isPlanned !== undefined) {
      parameters['where[payments][some][isPlanned]'] = item.payments.some?.isPlanned;
    }
    if (item.payments && item.payments.some?.amount?.gt !== undefined) {
      parameters['where[payments][some][amount][gt]'] = item.payments.some?.amount?.gt;
    }
    if (item.activities && item.activities.some?.claimAction !== undefined) {
      parameters['where[activities][some][claimAction]'] = item.activities.some?.claimAction;
    }
    if (item.activities && item.activities.some?.isPlanned !== undefined) {
      parameters['where[activities][some][isPlanned]'] = item.activities.some?.isPlanned;
    }
    if (item.activities && item.activities.none?.claimAction !== undefined) {
      parameters['where[activities][none][claimAction]'] = item.activities.none?.claimAction;
    }
    if (item.activities && item.activities.none?.isPlanned !== undefined) {
      parameters['where[activities][none][isPlanned]'] = item.activities.none?.isPlanned;
    }
    if (item.paymentPlans) {
      parameters['where[paymentPlans][some]'] = 'true';
    }
    if (item.creditorFeedbackRequired === true || item.creditorFeedbackRequired === false) {
      parameters['where[creditorFeedbackRequired]'] = item.creditorFeedbackRequired;
    }
  });
  if (getUserClaimsByCreditorPayload.skip) {
    parameters['skip'] = getUserClaimsByCreditorPayload.skip;
  }
  if (getUserClaimsByCreditorPayload.take) {
    parameters['take'] = getUserClaimsByCreditorPayload.take;
  }

  if (getUserClaimsByCreditorPayload.orderBy) {
    parameters['orderBy[' + getUserClaimsByCreditorPayload.orderBy.property + ']'] =
      getUserClaimsByCreditorPayload.orderBy.order;
  }

  return httpApi
    .get<GetUserClaimsByCreditorResponse>('users/' + getUserClaimsByCreditorPayload.userId + '/claimsAsCreditor', {
      params: parameters ? parameters : null,
    })
    .then(({ data }) => data);
};

// not yet implemented
export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

// Reset Password
export const resetPassword = (resetPasswordPayload: UserResetPasswordPayload): Promise<UserResetPasswordResponse> => {
  return httpApi
    .post<UserResetPasswordResponse>('/users/resetPassword', { ...resetPasswordPayload })
    .then(({ data }) => data);
};

export const verifySecurityCode = (
  securityCodePayload: SecurityCodePayload,
): Promise<CreateVerificationCodeResponse> => {
  return httpApi
    .post<CreateVerificationCodeResponse>('/users/verifySecurityCode', { ...securityCodePayload })
    .then(({ data }) => data);
};

export const setNewResetPassword = (newPasswordData: NewResetPasswordPayload): Promise<boolean | undefined> =>
  httpApi.post<boolean | undefined>('users/setNewPassword', { ...newPasswordData }).then(({ data }) => data);

// End of Reset Password

// Update password
export const verifyCurrentPassword = (currentPasswordData: CurrentPasswordData): Promise<undefined> => {
  return httpApi.post<undefined>('verifyCurrentPassword', { ...currentPasswordData }).then(({ data }) => data);
};

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<UserUpdatePasswordResponse> => {
  return httpApi.post<UserUpdatePasswordResponse>('updatePassword', { ...newPasswordData }).then(({ data }) => data);
};
