import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Dates } from '@app/constants/Dates';
import { getCreditorInvoices, GetInvoicesResponse, GetPayoutsResponse } from '@app/api/payments.api';
import { notificationController } from '@app/controllers/notificationController';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import * as S from '../../components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/PaymentsTable/PaymentsTable.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { readToken } from '@app/services/localStorage.service';
import { httpApi } from '@app/api/http.api';

const DebtCollectorPayouts: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [unpaidPayments, setUnpaidPayments] = useState<GetInvoicesResponse[]>([]);
  const [paidPaymentsByMonth, setPaidPaymentsByMonth] = useState<Record<string, GetInvoicesResponse[]>>({});
  const [activeMonthIndex, setActiveMonthIndex] = useState(0);
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);

  const monthsArray: Date[] = Array.from({ length: 12 }, (_, i) => {
    const date: Date = new Date();
    date.setMonth(date.getMonth() - i);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  }).filter((date) => date <= new Date());

  const fetchPayments = async (activeMonth: Date, userCreatedAt?: Date): Promise<void> => {
    try {
      setLoading(true);
      const now: Date = new Date();
      if (activeMonth > now) activeMonth = now;

      const startDate: Date = new Date(activeMonth.getFullYear(), activeMonth.getMonth(), 1);
      const endDate: Date =
        activeMonth.getMonth() === now.getMonth() && activeMonth.getFullYear() === now.getFullYear()
          ? new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999)
          : new Date(activeMonth.getFullYear(), activeMonth.getMonth() + 1, 0, 23, 59, 59, 999);

      const minDateUnpaidInvoices = userCreatedAt
        ? new Date(userCreatedAt)
        : new Date(new Date().setFullYear(new Date().getFullYear() - 4));
      const unpaidResponse: GetPayoutsResponse[] = await getCreditorInvoices({
        isPlanned: true,
        paymentDate: {
          gte: minDateUnpaidInvoices.toISOString(),
          lte: now.toISOString(),
        },
      });
      setUnpaidPayments(unpaidResponse);

      const paidResponse: GetPayoutsResponse[] = await getCreditorInvoices({
        isPlanned: false,
        paymentDate: {
          gte: startDate.toISOString(),
          lte: endDate.toISOString(),
        },
      });

      const monthKey: string = startDate.toLocaleString('default', { month: 'long', year: 'numeric' });
      setPaidPaymentsByMonth((prev) => ({ ...prev, [monthKey]: paidResponse }));
    } catch (error) {
      notificationController.error({
        message: t('common.error'),
        description: t('profile.nav.debtCollectorPayments.error'),
      });
    } finally {
      setLoading(false);
    }
  };

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const handleFileClick = useCallback(
    (url: string, claimReference: string): void => {
      const authHeader = {
        headers: { Authorization: `Bearer ${readToken()}` },
      };

      const cleanClaimReference = claimReference?.replaceAll('/', '-');
      let urlToFetch = url.split(cleanClaimReference + '/').pop();
      urlToFetch = encodeURIComponent(urlToFetch || '');
      if (urlToFetch) {
        fetch(httpApi.defaults.baseURL + '/claims/' + cleanClaimReference + '/file/' + urlToFetch, authHeader)
          .then((response) => {
            if (response.ok) {
              response.blob().then((blobby) => {
                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = url;
                anchor.click();
                document.body.removeChild(anchor);

                setTimeout(() => {
                  window.URL.revokeObjectURL(objectUrl);
                }, 100);
              });
            } else {
              notificationController.error({
                message: t('common.error'),
                description: `Failed to download ${url}`,
              });
            }
          })
          .catch(() => {
            notificationController.error({
              message: t('common.error'),
              description: `Network error downloading ${url}`,
            });
          });
      }
    },
    [anchor, t],
  );

  const handleDownloadAll = (monthKey: string) => {
    const payments = paidPaymentsByMonth[monthKey] || [];
    const invoicesToDownload = payments.filter((payment) => payment.invoiceFileUrl);

    if (invoicesToDownload.length === 0) {
      notificationController.info({ message: t('common.noFilesToDownload') });
      return;
    }

    invoicesToDownload.forEach((payment, index) => {
      setTimeout(() => {
        handleFileClick(payment.invoiceFileUrl || '', payment.reference);
      }, index * 500);
    });
  };

  useEffect(() => {
    fetchPayments(monthsArray[activeMonthIndex], user?.createdAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMonthIndex]);

  const columns = [
    {
      title: t('profile.nav.debtCollectorPayments.tableLabels.reference'),
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: t('profile.nav.debtCollectorPayments.tableLabels.amount'),
      dataIndex: 'invoiceTotal',
      key: 'invoiceTotal',
      render: (invoiceTotal: number) =>
        new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }).format(Math.abs(invoiceTotal)),
    },
    {
      title: t('profile.nav.debtCollectorPayments.tableLabels.date'),
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (date: string) => (date ? Dates.format(new Date(date).getTime(), 'DD.MM.YYYY') : '-'),
    },
    {
      title: t('profile.nav.debtCollectorPayments.tableLabels.download'),
      render: (record: Record<string, string>) => {
        if (!record || !record.invoiceFileUrl) {
          return <Typography.Text>{t('profile.nav.debtCollectorPayments.tableLabels.noInvoice')}</Typography.Text>;
        }

        return (
          <Button
            type="link"
            icon={<DownloadOutlined />}
            onClick={() => handleFileClick(record.invoiceFileUrl, record.reference)}
          >
            {t('profile.nav.debtCollectorPayments.tableLabels.download')}
          </Button>
        );
      },
    },
  ];

  const tabItems = monthsArray.map((date, index) => {
    const monthKey = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    return {
      label: monthKey,
      key: index.toString(),
      children: (
        <Card>
          {paidPaymentsByMonth[monthKey]?.length > 0 && (
            <Row justify="end" style={{ marginBottom: 10 }}>
              <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownloadAll(monthKey)}>
                {t('common.downloadAll')}
              </Button>
            </Row>
          )}
          <S.PaymentHistoryTable
            loading={loading}
            dataSource={paidPaymentsByMonth[monthKey] || []}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        </Card>
      ),
    };
  });

  return (
    <Row gutter={[0, 30]} className="p-6">
      <Col span={24}>
        <BaseForm.Title>{t('profile.nav.debtCollectorPayments.title')}</BaseForm.Title>
        <BaseForm.Item>{t('profile.nav.debtCollectorPayments.description')}</BaseForm.Item>
      </Col>
      <Col span={24}>
        <Card title={t('profile.nav.debtCollectorPayments.tableLabels.title')}>
          <S.PaymentHistoryTable
            loading={loading}
            dataSource={unpaidPayments}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Tabs
          activeKey={activeMonthIndex.toString()}
          onChange={(key) => setActiveMonthIndex(Number(key))}
          className="w-full"
          items={tabItems}
        />
      </Col>
    </Row>
  );
};

export default DebtCollectorPayouts;
