import React from 'react';
import { useLanguage } from '@app/hooks/useLanguage';
import * as S from './LanguagePicker.styles';
import ReactCountryFlag from 'react-country-flag';
import { Dropdown, MenuProps } from 'antd';
import { LanguageType } from '@app/interfaces/interfaces';
import styled from 'styled-components';

const LanguageSelector = styled(Dropdown.Button)`
  box-shadow: var(--box-shadow);
  width: auto;
  border-radius: 8px;

  button:first-of-type {
    cursor: default;
    border: none;

    span {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }
  }

  button:last-of-type {
    border: 1px solid var(--layout-sider-bg-color);
  }

  @media (min-width: 768px) {
    margin-right: 2.5rem;
    position: absolute;
    right: 0;
  }
`;

type LanguagePickerProps = {
  isExtended?: boolean;
};

type CustomMenuItem = {
  label: string;
  key: string;
  icon: JSX.Element;
};

const items: CustomMenuItem[] = [
  {
    label: 'EN',
    key: '1',
    icon: <ReactCountryFlag svg countryCode="GB" />,
  },
  {
    label: 'DE',
    key: '2',
    icon: <ReactCountryFlag svg countryCode="DE" />,
  },
  {
    label: 'JP',
    key: '3',
    icon: <ReactCountryFlag svg countryCode="JP" />,
  },
  {
    label: 'ES',
    key: '4',
    icon: <ReactCountryFlag svg countryCode="ES" />,
  },
  {
    label: 'FR',
    key: '5',
    icon: <ReactCountryFlag svg countryCode="FR" />,
  },
  {
    label: 'IT',
    key: '6',
    icon: <ReactCountryFlag svg countryCode="IT" />,
  },
  {
    label: 'DK',
    key: '7',
    icon: <ReactCountryFlag svg countryCode="DK" />,
  },
];

export const LanguagePicker = ({ isExtended }: LanguagePickerProps): JSX.Element => {
  const { language, setLanguage } = useLanguage();

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setLanguage(items[parseInt(e.key) - 1].label.toLowerCase() as LanguageType);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  if (isExtended) {
    return (
      <LanguageSelector
        menu={menuProps}
        placement="bottom"
        icon={<ReactCountryFlag svg countryCode={language == 'en' ? 'GB' : language.toUpperCase()} />}
      >
        {language.toUpperCase()}
      </LanguageSelector>
    );
  }

  return (
    <S.ButtonGroup>
      <S.Btn
        size={'small'}
        type={language == 'en' ? 'primary' : 'link'}
        icon={<ReactCountryFlag svg countryCode="GB" />}
        onClick={() => setLanguage('en')}
      />
      <S.Btn
        size={'small'}
        type={language == 'de' ? 'primary' : 'link'}
        icon={<ReactCountryFlag svg countryCode="DE" />}
        onClick={() => setLanguage('de')}
      />
      <S.Btn
        size={'small'}
        type={language == 'dk' ? 'primary' : 'link'}
        icon={<ReactCountryFlag svg countryCode="DK" />}
        onClick={() => setLanguage('dk')}
      />
    </S.ButtonGroup>
  );
};
